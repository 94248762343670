var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-content-main"},[_c('div',{staticClass:"create-form"},[_c('div',{staticStyle:{"position":"relative","width":"100%","display":"block"}},[_c('div',{staticClass:"mb-2 row"},[_c('h2',{staticClass:"col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"}),_c('div',{staticClass:"col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"},[_c('button',{staticClass:"button-back-dashboard back-btn",on:{"click":function($event){return _vm.returnList()}}},[_vm._v(" 戻る ")])])]),_c('div',{staticClass:"customContentMoblie"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-3 px-1"},[(_vm.listMessageByChat && _vm.listMessageByChat.image)?_c('img',{attrs:{"src":_vm.env + _vm.listMessageByChat.image,"width":"70px","height":"70px"}}):(
                _vm.listMessageByChat.image == null ||
                _vm.listMessageByChat.image == ''
              )?_c('img',{attrs:{"src":require("@/assets/img/no-image.jpg"),"width":"70px","height":"70px"}}):_vm._e()]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.listMessageByChat.title))])])])]),_c('div',{staticClass:"d-flex flex-nowrap align-items-center px-3",staticStyle:{"height":"30px"}}),_c('div',[_c('div',{staticClass:"chat-mobile",staticStyle:{"background-color":"#668ad8b8","overflow":"auto","height":"calc(100vh - (140px + 111px))","position":"relative"},attrs:{"id":"bodyChat"}},_vm._l((_vm.listMessageByChat.message),function(itemMess,index){return _c('div',{key:index,staticStyle:{"padding":"10px 12px"}},[(itemMess.user_type === 4 || itemMess.user_type === 5)?_c('div',{staticClass:"d-flex flex-nowrap align-items-center"},[_c('div',{staticClass:"mt-2 ml-2"},[_c('div',{staticClass:"custom-name-user"},[_vm._v(" "+_vm._s(itemMess.last_name)+" "+_vm._s(itemMess.first_name)+" ")]),_c('div',{staticClass:"customChatUser"},[_c('div',{staticClass:"d-flex flex-column p-3"},[_c('div',{staticStyle:{"word-wrap":"break-word"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.replaceMessage(itemMess.message))}})])])])])]):_vm._e(),(itemMess.user_type !== 4 && itemMess.user_type !== 5)?_c('div',{staticClass:"d-flex flex-nowrap flex-row-reverse align-items-center"},[_c('div',{staticClass:"customChatAdmin mt-3"},[_c('div',{staticClass:"d-flex flex-column p-3"},[_c('div',{staticStyle:{"word-wrap":"break-word"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.replaceMessage(itemMess.message))}})])])]),(
                  itemMess.id !== _vm.listMessageByChat.message[0].id &&
                  _vm.checkDelete != itemMess.id
                )?_c('div',{staticClass:"pr-3 mt-3 del-mess",on:{"click":function($event){return _vm.deletMessage(itemMess.id)}}},[_c('i',{staticClass:"fas fa-times",staticStyle:{"color":"white"}})]):_vm._e()]):_vm._e()])}),0),_c('div',{staticClass:"custom-control CustomInputChat w-100 pl-0",staticStyle:{"background-color":"white","border-style":"groove"}},[_c('div',{staticClass:"p-1 d-flex flex-nowrap justify-content-center align-items-center"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.titleSendMessage),expression:"titleSendMessage"}],staticClass:"hoverMouse pl-0 px-4 form-control",staticStyle:{"border":"1px solid #fff","width":"90%","background-color":"#d8dbe0","border-radius":"30px","resize":"none"},attrs:{"placeholder":"返信内容を記載","rows":"3","no-resize":""},domProps:{"value":(_vm.titleSendMessage)},on:{"keyup":_vm.checkLimitLength,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();_vm.titleSendMessage += '\n'},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.ClickSendMessage()}],"input":function($event){if($event.target.composing)return;_vm.titleSendMessage=$event.target.value}}}),_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center",staticStyle:{"width":"10%"}},[_c('img',{staticStyle:{"width":"18px","height":"20px","cursor":"pointer"},attrs:{"src":require("@/assets/img/send.png")},on:{"click":function($event){return _vm.ClickSendMessage()}}}),_c('div',[_vm._v("返信")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }